import React from 'react';
import Header from '../componantes/Globals/Header';
import bg from '../images/ban2.jpeg';
import image from '../images/ban1.jpeg';
import image2 from '../images/ban4.jpeg';

export const QuiSommesNous = () => {
  return (
    <div className='bg-[#f1f1f1]'>
      <div className='relative pb-4  w-[100%] h-[50vh] lg:h-[60vh]'>
        <div className='absolute top-0 bottom-0 left-0 right-0 '>
          <img src={bg} className=' h-full w-full object-cover blur-[2]' />
        </div>
        <div className=' bg-[#000000a1] absolute top-0 left-0 right-0 bottom-0 z-10'></div>
        <Header />
        <div>
          <p className='mb-6 translate-y-[25%] w-[90%] mx-auto text-center text-[1.5rem] lg:text-[2.5rem] text-white font-semibold absolute left-1/2 lg:translate-y-[70%] -translate-x-1/2 z-50'>
            Unissons nos ressources mentales et physiques pour <br /> bâtir un
            Cameroun meilleur et plus résilient.
          </p>
        </div>
      </div>
      <div className='relative z-40 w-[100%] mx-auto text-center py-5 '>
        <h1 className='lg:mb-6  text-[1.5rem] md:text-[3rem] font-semibold'>
          À Propos de Nous
        </h1>
      </div>
      <div className=' h-[100vh] bg-[#f1f1f1]'>
        <div className='py-4 lg:py-10 '>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-col items-center justify-center py-10 space-y-10 lg:space-y-0 lg:flex-row lg:space-x-10'>
              <div className=' basis-[100%] lg:basis-[60%] order-2 lg:order-1'>
                <h2 className='mb-4 text-[1.5rem] lg:text-[2.5rem] font-semibold'>
                  Introduction
                </h2>
                <p className='mb-6 text-lg'>
                  "LIHLIMAH Internationale est née en 2021 de la prise de
                  conscience collective des membres de la communauté BASSA BATI
                  MPOO. Inspirés par le travail exemplaire de l'association NDAB
                  BIKOKOO PIL-GWEHA, qui rassemble plus de 3000 membres de la
                  communauté pour le rapatriement des défunts, nous avons décidé
                  de nous mobiliser pour aborder d'autres enjeux sociaux et
                  économiques importants."
                </p>
                <h2 className='mb-4 text-[1.5rem] lg:text-[2.5rem] font-semibold'>
                  Notre Histoire
                </h2>
                <p className='mb-6 text-lg'>
                  "Nous avons été profondément inspirés par l'association NDAB
                  BIKOKOO PIL-GWEHA, une organisation qui a réussi à mobiliser
                  notre communauté autour d'une noble cause. Comme eux, nous
                  avons identifié des besoins critiques au sein de notre
                  communauté et avons choisi d'agir en conséquence. Notre
                  association a été officiellement enregistrée le 14 janvier
                  2022 par la préfecture des Alpes-Maritimes."
                </p>
                <h2 className='mb-4 text-[1.5rem] lg:text-[2.5rem] font-semibold '>
                  Notre Mission
                </h2>
                <p className='mb-6 text-lg'>
                  "Notre engagement est d'améliorer les conditions de vie des
                  Camerounais, en particulier au sein de notre communauté BASSA
                  BATI MPOO, à travers divers projets et investissements. Notre
                  objectif principal est de créer des emplois et d'accroître
                  l'accessibilité aux denrées alimentaires."
                </p>
              </div>
              <div className=' pb-6 lg:pb-0 order-1 lg:order-2 w-[100%] lg:basis-[40%] flex items-end justify-end'>
                <img
                  src={image}
                  className=' lg:rotate-6 p-6 border-[6px] shadow-lg  border-white h-[50vh] w-full  rounded-xl object-cover'
                  alt='banniere'
                />
              </div>
            </div>
            <div className='flex flex-col items-center justify-center py-10 space-y-10 lg:space-y-0 lg:flex-row lg:space-x-10'>
              <div className=' w-full lg:basis-[40%] flex items-end justify-end'>
                <img
                  src={image2}
                  className=' lg:rotate-6 p-6 border-[6px] shadow-lg  border-white h-[50vh] w-full  rounded-xl object-cover'
                />
              </div>
              <div className=' basis-[60%]'>
                <h2 className='mb-4 text-[1.5rem] lg:text-[2.5rem] font-semibold'>
                  Nos Réalisations
                </h2>
                <ul className='pl-6 mb-6 list-disc'>
                  <li className='mb-2'>
                    Projet de Culture du Manioc : "Cette année, nous avons uni
                    nos efforts pour cultiver dix hectares de champs de manioc
                    répartis dans huit villages différents."
                  </li>
                  <li className='mb-2'>
                    Investissements Impactants : "Nous nous réunissons
                    régulièrement pour investir dans des projets qui apportent
                    des changements significatifs au Cameroun et dans notre
                    communauté."
                  </li>
                </ul>

                <h2 className='mb-4 text-[1.5rem] lg:text-[2.5rem] font-semibold'>
                  Appel à l'Action
                </h2>
                <p className='mb-6 text-lg'>
                  "Rejoignez-nous dans cette noble quête pour créer un avenir
                  plus sûr et prospère pour la communauté BASSA BATI MPOO et
                  pour l'ensemble du Cameroun. Votre contribution, qu'elle soit
                  intellectuelle, physique ou financière, fait toute la
                  différence."
                </p>

                <h2 className='mb-4 text-[1.5rem] lg:text-[2.5rem] font-semibold'>
                  Contactez-Nous
                </h2>
                <p className='text-lg'>
                  Pour en savoir plus sur nos projets ou pour devenir membre,
                  veuillez nous contacter à lihaalimahol@gmail.com ou en Europe
                  au 00 33 6 59 34 56 40, ou au Cameroun au 00 237 6 99 92 20
                  76.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
