import React from 'react';

const NotFound = ({ message }) => {
  return (
    <div className=' w-full bg-green-50 p-3'>
      <span className=' text-green-600'>{message}</span>
    </div>
  );
};

export default NotFound;
