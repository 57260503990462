import React from 'react';

export default function FormTextarea({
  title,
  type,
  placeholder,
  value,
  onChange,
}) {
  return (
    <div>
      <label className='text-base md:text-[1.2rem] font-medium' htmlFor={type}>
        {title}
      </label>
      <textarea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name=''
        id=''
        rows='8'
        className='text-sm md:text-[1rem] bg-[#e8e8e8] p-[0.45rem] rounded-sm outline-none  w-full mt-2 resize-none'
      />
    </div>
  );
}
