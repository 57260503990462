import ReactPDF, {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import logo from '../images/logo.png';
import tampont from '../images/tp.jpeg';
import Checked from '../images/checked.png';
import unChecked from '../images/uncheked.png';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import { useSearchParams, Link } from 'react-router-dom';

const styles = StyleSheet.create({
  container: {
    width: '98%',
    padding: '2%',
    margin: 'auto',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  logo: {
    objectFit: 'cover',
    width: '24px',
    height: '24px',
  },
  title: {
    fontWeight: 'bold',
    color: '#008000',
  },
  paragraph: {
    paddingTop: '5px',
    marginBottom: '2px',
  },
  mb10: {
    marginBottom: '10px',
  },
  checkboxContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
  flexSpaceContainer: {},

  borderedCheckboxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    width: '4px',
    height: '4px',
  },
  spaceXContainer: {
    width: '100%',
  },
});

const SignatureListPdf = () => {
  const [signatures, setSignatures] = useState([]);
  const [id, setId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('d');
    setId(id);
  }, []);

  const fetchSignatures = async () => {
    try {
      const response = await axios.get('/api/members');
      setSignatures(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchSignatures();
  }, []);

  const downloadLink = (member) => (
    <PDFDownloadLink
      document={
        <Document>
          <Page size='A4'>
            <View
              style={{
                width: '98%',
                padding: '2%',
                margin: 'auto',
                fontSize: 14,
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Image
                  src={logo}
                  style={{ objectFit: 'cover', width: 70, height: 70 }}
                />
                <Text
                  style={{
                    fontWeight: 'bold',
                    color: '#008000',
                    marginTop: 10,
                    marginBottom: 10,
                    fontSize: 18,
                  }}
                >
                  BULLETIN D’ADHÉSION À LIHÂÂ LI MAHOL INTERNATIONALE
                </Text>
              </View>

              <Text>
                Pour adhérer à LIHÂÂ LI MAHOL, il vous suffit de compléter et de
                nous renvoyer le bulletin ci-dessous accompagné de votre
                règlement. Un reçu vous sera envoyé dès réception
              </Text>

              <View style={styles.mb10}>
                <View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <View>
                      <Text>Je sousigné(e)s,</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                      {member.isMme && (
                        <Image
                          src={Checked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      {!member.isMme && (
                        <Image
                          src={unChecked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}

                      <Text>Mme</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                      {member.isMr && (
                        <Image
                          src={Checked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      {!member.isMr && (
                        <Image
                          src={unChecked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      <Text>Mr</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                      {member.isMlle && (
                        <Image
                          src={Checked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      {!member.isMlle && (
                        <Image
                          src={unChecked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      <Text>Mlle</Text>
                    </View>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', marginBottom: 5 }}>
                  <Text style={{ fontWeight: 500, marginRight: 10 }}>Nom:</Text>
                  <Text>{member?.name}</Text>
                </View>
                <View style={{ flexDirection: 'row', marginBottom: 5 }}>
                  <Text style={{ fontWeight: 500, marginRight: 10 }}>
                    Prénom:
                  </Text>
                  <Text>{member?.firstName}</Text>
                </View>
                <View style={{ flexDirection: 'row', marginBottom: 5 }}>
                  <Text tyle={{ fontWeight: 500, marginRight: 10 }}>
                    Adresse:{' '}
                  </Text>
                  <Text>{member?.address}</Text>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 5,
                    marginTop: 10,
                    justifyContent: 'space-between',
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      marginBottom: 5,
                    }}
                  >
                    <Text style={{ marginRight: 5 }}>CP:</Text>
                    <Text>{member?.cp}</Text>
                  </View>
                  <View style={{ flexDirection: 'row', marginBottom: 5 }}>
                    <Text style={{ marginRight: 5 }}>Ville:</Text>
                    <Text>{member?.ville}</Text>
                  </View>
                  <View style={{ flexDirection: 'row', marginBottom: 5 }}>
                    <Text style={{ marginRight: 5 }}>Pays:</Text>
                    <Text>{member?.contry}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 5,
                    justifyContent: 'space-between',
                  }}
                >
                  <View style={{ flexDirection: 'row', marginBottom: 5 }}>
                    <Text style={{ marginRight: 5 }}>Téléphone:</Text>
                    <Text>{member?.phone}</Text>
                  </View>
                  <View style={{ flexDirection: 'row', marginBottom: 5 }}>
                    <Text style={{ marginRight: 5 }}>Email:</Text>
                    <Text>{member?.email}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 5,
                    justifyContent: 'space-between',
                  }}
                >
                  <View style={{ flexDirection: 'row', marginBottom: 5 }}>
                    <Text style={{ marginRight: 5 }}>Je suis</Text>
                    <View style={{ flexDirection: 'row' }}>
                      {member.isBassa && (
                        <Image
                          src={Checked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      {!member.isBassa && (
                        <Image
                          src={unChecked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      <Text>BASSA</Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row' }}>
                    {member.isBati && (
                      <Image src={Checked} style={{ width: 14, height: 14 }} />
                    )}
                    {!member.isBati && (
                      <Image
                        src={unChecked}
                        style={{ width: 14, height: 14 }}
                      />
                    )}
                    <Text>BÂTI</Text>
                  </View>
                  <View style={{ flexDirection: 'row' }}>
                    {member.isMpoo && (
                      <Image src={Checked} style={{ width: 14, height: 14 }} />
                    )}
                    {!member.isMpoo && (
                      <Image
                        src={unChecked}
                        style={{ width: 14, height: 14 }}
                      />
                    )}
                    <Text>MPO'O</Text>
                  </View>
                </View>
                <View
                  style={{
                    border: '1px solid green',
                    paddingTop: 5,
                    paddingRight: 5,
                    paddingLeft: 5,
                    paddingBottom: 5,
                  }}
                >
                  <View style={{ flexDirection: 'row' }}>
                    {member.isEuro && (
                      <Image src={Checked} style={{ width: 14, height: 14 }} />
                    )}
                    {!member.isEuro && (
                      <Image
                        src={unChecked}
                        style={{ width: 14, height: 14 }}
                      />
                    )}
                    <Text>J’adhère à LIHLIMAH pour un montant de 50 €</Text>
                  </View>
                  <View style={{ flexDirection: 'row' }}>
                    {member.isCfa && (
                      <Image src={Checked} style={{ width: 14, height: 14 }} />
                    )}
                    {!member.isCfa && (
                      <Image
                        src={unChecked}
                        style={{ width: 14, height: 14 }}
                      />
                    )}
                    <Text>
                      J’adhère à LIHLIMAH pour un montant de 30 000 FCFA
                    </Text>
                  </View>
                  <View style={{ flexDirection: 'row' }}>
                    {member.isDollar && (
                      <Image src={Checked} style={{ width: 14, height: 14 }} />
                    )}
                    {!member.isDollar && (
                      <Image
                        src={unChecked}
                        style={{ width: 14, height: 14 }}
                      />
                    )}
                    <Text>J’adhère à LIHLIMAH pour un montant de 55 $</Text>
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 4 }}>
                    <View style={{ marginRight: 5, flexDirection: 'row' }}>
                      {member.iSparain && (
                        <Image
                          src={Checked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      {!member.iSparain && (
                        <Image
                          src={unChecked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      <Text>Parrainé par un tiers Nom et Prénom :</Text>
                    </View>
                    <Text>{member?.parainName}</Text>
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 4 }}>
                    <View style={{ marginRight: 5, flexDirection: 'row' }}>
                      {member.isSoutient && (
                        <Image
                          src={Checked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      {!member.isSoutient && (
                        <Image
                          src={unChecked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      <Text>
                        je soutiens LIHLIMAH en versant un supplément de:
                      </Text>
                    </View>
                    <Text>{member?.donateAmount}</Text>
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 4 }}>
                    {member.istotalAmount && (
                      <Image src={Checked} style={{ width: 14, height: 14 }} />
                    )}
                    {!member.istotalAmount && (
                      <Image
                        src={unChecked}
                        style={{ width: 14, height: 14 }}
                      />
                    )}
                    <Text style={{ marginRight: 10 }}>
                      Soit un montant total de :
                    </Text>
                    <Text>{member?.totalAmount}</Text>
                  </View>

                  <View style={{ flexDirection: 'row', marginTop: 4 }}>
                    {member.isByMe && (
                      <Image src={Checked} style={{ width: 14, height: 14 }} />
                    )}
                    {!member.isByMe && (
                      <Image
                        src={unChecked}
                        style={{ width: 14, height: 14 }}
                      />
                    )}
                    <Text>Par moi-même</Text>
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 4 }}>
                    <View style={{ flexDirection: 'row' }}>
                      {member.isFreind && (
                        <Image
                          src={Checked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      {!member.isFreind && (
                        <Image
                          src={unChecked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      <Text style={{ marginRight: 10 }}>
                        Par un tiers : Nom Prénom :
                      </Text>
                    </View>
                    <Text>{member?.freindName}</Text>{' '}
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 4 }}>
                    <View style={{ flexDirection: 'row' }}>
                      {member.isCheque && (
                        <Image
                          src={Checked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      {!member.isCheque && (
                        <Image
                          src={unChecked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      <Text style={{ marginRight: 10 }}>
                        Chèque à l’ordre de l’Association Lihââ Li Mahol
                        Internationale
                      </Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 4 }}>
                    <View style={{ flexDirection: 'row' }}>
                      {member.isRIB && (
                        <Image
                          src={Checked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      {!member.isRIB && (
                        <Image
                          src={unChecked}
                          style={{ width: 14, height: 14 }}
                        />
                      )}
                      <Text style={{ marginRight: 10 }}>
                        Virement (RIB ci-dessous) sur le compte de Lihââ Li
                        Mahol Internationale
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <Text style={{ marginTop: 10 }}>
                NB : En cas de non validation de votre adhésion par la
                commission de contrôle votre paiement vous sera intégralement
                remboursé
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 10,
                  marginBottom: 10,
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <Text style={{ marginRight: 5 }}>Fait à :</Text>
                  <Text>{member?.makeAt}</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ marginRight: 5 }}>Le :</Text>
                  <Text>{member?.date}</Text>
                </View>
              </View>
              <View>
                <View
                  style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '40%',
                    margin: 'auto',
                  }}
                >
                  <Text>Cachet et/ou Signature:</Text>
                  <View
                    style={{
                      border: '1px solid green',
                      width: '100%',
                      flexDirection: 'row',
                    }}
                  >
                    <View style={{ width: '40%' }}>
                      <Image
                        src={tampont}
                        style={{
                          objectFit: 'cover',
                          width: '100%',
                          height: 'auto',
                        }}
                      />
                    </View>
                    <View style={{ width: '60%' }}>
                      <Image
                        src={member.signature}
                        style={{ height: 80, width: '100%' }}
                      />
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Text style={{ marginTop: 10, fontSize: 16 }}>
                  Association Lihââ Li Mahol Internationale
                  {'\n'}
                  43 Route de Sospel 06500 MENTON / Email :
                  lihaalimahol@gmail.com/ {'\n'}Tel : + 33 6 59 34 56 40
                </Text>
              </View>
            </View>
          </Page>
        </Document>
      }
      fileName={`inscription${member.name}.pdf`}
    >
      <button className='text-green-700 '> Télécharger</button>
    </PDFDownloadLink>
  );

  if (id === 'mT54KAdmin') {
    navigate('/');
  }
  return (
    <div>
      <div className='container p-4 mx-auto'>
        <h1 className='mb-4 text-2xl font-bold'>Inscription membre</h1>
        {signatures.length === 0 ? (
          <p className='text-gray-500'>No signatures found.</p>
        ) : (
          <ul className='space-y-2'>
            {signatures.map((member) => (
              <li
                key={member._id}
                className='flex items-center w-full pb-4 space-x-3 border-b-2 border-slate-400'
              >
                <div>
                  <span>Inscription de {member.name}</span>
                </div>
                {downloadLink(member)}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SignatureListPdf;
