import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import ClipLoader from 'react-spinners/ClipLoader';

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#1e4ea0',
  // width: '100%',
};

const Layout = ({ children }) => {
  const [isMouseEnter, setIsMouseEnter] = useState('');

  const location = useLocation();

  return (
    <main className=' pb-3'>
      <div
        className={`  w-[95%] xl:w-[90%] 2xl:w-[80%] mx-auto   grid grid-cols-5 md:grid-cols-6 lg:grid-cols-5 gap-4 mt-8`}
      >
        <div className={` col-span-5 md:col-span-2 lg:col-span-1`}>
          <div className='bg-white shadow-sm rounded-md '>
            <ul>
              <li
                onMouseEnter={() => setIsMouseEnter('blogs')}
                onMouseLeave={() => setIsMouseEnter('')}
                className={`${
                  location.pathname === '/admin/blogs' &&
                  'bg-[#114cb3] text-white'
                } px-4 py-2 hover:bg-[#114cb3] hover:text-white border-t-2 ${
                  isMouseEnter === 'profil'
                    ? 'border-t-white'
                    : ' border-t-gray-400'
                }  `}
              >
                <Link
                  to={'/admin/blogs'}
                  className=' flex items-center space-x-2'
                >
                  <span>
                    <svg
                      fill={`${
                        location.pathname === '/admin/blogs' ||
                        isMouseEnter === 'blogs'
                          ? '#ffff'
                          : '#114cb3'
                      } `}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      className=' h-4'
                    >
                      <path d='M19.5 21a3 3 0 0 0 3-3v-4.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h15ZM1.5 10.146V6a3 3 0 0 1 3-3h5.379a2.25 2.25 0 0 1 1.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 0 1 3 3v1.146A4.483 4.483 0 0 0 19.5 9h-15a4.483 4.483 0 0 0-3 1.146Z' />
                    </svg>
                  </span>
                  <span>Blogs</span>
                </Link>
              </li>

              <li
                onMouseEnter={() => setIsMouseEnter('deconnexion')}
                onMouseLeave={() => setIsMouseEnter('')}
                className={`${
                  isMouseEnter === 'deconnexion' && 'bg-[#114cb3]'
                } px-4 py-2 hover:bg-[#114cb3] hover:text-white border-t-2 ${
                  isMouseEnter === 'deconnexion'
                    ? 'border-t-white'
                    : ' border-t-[#b5b5b5]'
                }  `}
              >
                <button
                  onClick={() => {
                    localStorage.removeItem('user');
                    window.location.reload();
                  }}
                  className=' cursor-pointer flex items-center space-x-2'
                >
                  <span>
                    <svg
                      fill={`${
                        isMouseEnter === 'deconnexion' ? '#ffff' : '#114cb3'
                      } `}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 448 512'
                      className=' h-4'
                    >
                      <path d='M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z' />
                    </svg>
                  </span>
                  <span> Déconnexion</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-span-5 md:col-span-4 lg:col-span-4 '>
          {children}
        </div>
      </div>
    </main>
  );
};

export default Layout;
