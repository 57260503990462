import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthCrad from '../componantes/auth/AuthCrad';
import InputField from '../componantes/auth/InputField';
import PrimaryButton from '../componantes/Globals/PrimaryButton';
import { useHttRequest } from '../hooks/useHttRequest';
import Spinner from '../componantes/Globals/Spinner';

export const Auth = () => {
  const navigate = useNavigate();
  const { post, response } = useHttRequest();

  const [loading, setLoading] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [name, setName] = useState('');
  const [user, setUser] = useState(null);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log('submit');
    if (isLogin) {
      const url = '/api/users/login';
      const data = { email, password: password.trim() };
      post(url, data);
    } else {
      const url = '/api/users';
      const data = { email, password: password.trim(), name };
      post(url, data);
    }
  };

  useEffect(() => {
    if (response.error) {
      toast.error(response.error, {
        position: 'top-center',
      });
    }
  }, [response.error]);

  useEffect(() => {
    if (response.success) {
      const user = localStorage.setItem(
        'user',
        JSON.stringify(response.document)
      );
      setUser(user);
    }
  }, [response.success]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setUser(user);

    if (user && user.token) {
      window.location.reload();
      return navigate('/');
    }
  }, [user]);

  return (
    <AuthCrad title={`${isLogin ? 'Connexion' : 'Inscription'} `}>
      <Spinner loading={response.isPending} />
      <form onSubmit={submitHandler} className=' flex flex-col space-y-4 mt-4'>
        {!isLogin && (
          <InputField
            value={name}
            onChange={(e) => setName(e.target.value)}
            title='Nom'
            type='text'
            placeholder='Entrer votre nom'
          />
        )}

        <InputField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          title='Email'
          type='email'
          placeholder='Entrer votre email'
        />
        <InputField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          title='Mot de passe'
          type='password'
          placeholder='Entrer votre mot de passe'
        />

        {/* <div className=' flex space-x-2 justify-end text-[#114cb3]'>
          <span
            onClick={() => navigate('/mot-de-passe-oublie')}
            className=' cursor-pointer hover:text-[#d60e0e]'
          >
            Mot de passe oublié?
          </span>
        </div> */}

        <PrimaryButton text={`${isLogin ? 'Se connecter' : "S'inscrire"} `} />

        <div className=' flex space-x-2 justify-end text-[#114cb3]'>
          <span
            onClick={() => setIsLogin(!isLogin)}
            className=' cursor-pointer hover:text-[#d60e0e]'
          >
            <span>{isLogin ? 'Inscription' : 'connexion'} </span>
          </span>
        </div>
      </form>
    </AuthCrad>
  );
};
