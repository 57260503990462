import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import bg from '../../images/bg.jpg';
import logo from '../../images/logo.png';
import { BiLogoFacebook } from 'react-icons/bi';
import { AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';

const Header = () => {
  const [user, setUser] = useState('');
  const [userLoading, setUserLoading] = useState(false);
  useEffect(() => {
    const getuser = () => {
      const user = JSON.parse(localStorage.getItem('user'));
      setUser(user);
      setUserLoading(true);
    };
    getuser();
    return () => {};
  }, []);
  const navigate = useNavigate();
  return (
    <div className='w-[98%]  relative z-20 lg:w-[70%] md:w-[85%] mx-auto py-2 px-2 md:px-5 bg-[#3d3c3ca0] rounded-br-[10px] rounded-bl-[10px]'>
      <div>
        <div className=' flex justify-between items-center border-[#888888] pb-2 border-b-[1px]'>
          <div className='flex items-center justify-between space-x-3 text-center text-white '>
            <div className='flex-col md:flex-row  flex items-center justify-between space-x-2 text-white '>
              <span className='p-4 bg-gray-700 rounded-full shadow-md '>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-4 h-4'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z'
                  />
                </svg>
              </span>

              <span className='font-semibold md:text-[1rem] text-[0.7rem] '>
                Téléphone:+33 6 59 34 56 40
              </span>
            </div>
            <div className=' flex-col md:flex-row flex items-center justify-between space-x-2 text-white '>
              <span className='p-4 bg-gray-700 rounded-full shadow-md '>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  class='w-4 h-4'
                >
                  <path
                    fill-rule='evenodd'
                    d='M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z'
                    clip-rule='evenodd'
                  />
                </svg>
              </span>

              <span className='font-semibold md:text-[1rem] text-[0.7rem] '>
                Email:lihaalimahol@gmail.com
              </span>
            </div>
          </div>
          <div className='md:flex hidden items-center space-x-2 '>
            <a
              href='https://web.facebook.com/profile.php?id=100077241822012'
              target='_blank'
              rel='noopener noreferrer'
              className='p-4 bg-gray-700 rounded-full shadow-md'
            >
              <BiLogoFacebook className='text-white text-[1.5rem]' />
            </a>
            <Link to={'/'} className='p-4 bg-gray-700 rounded-full shadow-md '>
              <AiOutlineInstagram className=' text-white text-[1.5rem]' />
            </Link>{' '}
            <Link to={'/'} className='p-4 bg-gray-700 rounded-full shadow-md '>
              <AiOutlineTwitter className=' text-white text-[1.5rem]' />
            </Link>
          </div>
        </div>
      </div>
      <div className='mt-2 '>
        <ul className='flex items-center justify-between '>
          <li>
            <Link to={'/'}>
              <img
                src={logo}
                className='object-cover w-10 h-10 lg:h-16 lg:w-16'
              />
            </Link>
          </li>
          <div className='flex items-center space-x-3 md:space-x-4 '>
            <li>
              <Link
                to={'/qui-sommes-nous'}
                className='  hover:text-orange-700 md:p-5 md:py-2 text-white rounded-md font-semibold lg:text-[1.2rem] text-[0.8rem]'
              >
                Qui Sommes Nous ?
              </Link>
            </li>
            <li>
              <Link
                to={'/blogs'}
                className='  hover:text-orange-700  md:p-5  md:py-2 text-white rounded-md font-semibold lg:text-[1.2rem] text-[0.8rem]'
              >
                Blog
              </Link>
            </li>
            {user && user.token ? (
              <li>
                <Link
                  to={'/admin/blogs'}
                  className='   md:p-5  md:py-2 text-white rounded-md font-semibold lg:text-[1.2rem] text-[0.9rem]'
                >
                  Admin
                </Link>
              </li>
            ) : (
              <>
                {' '}
                <li>
                  <Link
                    to={'/connexion'}
                    className='  p-5 py-2 text-white rounded-md font-semibold lg:text-[1.2rem] text-[0.9rem]'
                  >
                    Connexion
                  </Link>
                </li>
                <li>
                  <Link
                    to={'/inscription'}
                    className=' bg-orange-600 hover:bg-orange-700 p-5 py-2 text-white rounded-md font-semibold lg:text-[1.2rem] text-[0.9rem]'
                  >
                    S'inscrire
                  </Link>
                </li>
              </>
            )}
          </div>
        </ul>
      </div>{' '}
    </div>
  );
};

export default Header;
