import React from 'react';

const InputField = ({ title, type, placeholder, value, onChange }) => {
  return (
    <div className=' flex flex-col '>
      <label className=' text-[0.9rem] md:text-[1.2rem]' htmlFor={type}>
        {title}
      </label>
      <input
        value={value}
        onChange={onChange}
        id={type}
        className=' text-[0.9rem] md:text-[1rem] bg-[#dfdfdf] p-2 rounded-sm outline-none border-2 border-gray-400'
        type={type}
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputField;
