import React, { useState } from 'react';
import logo from '../images/logo.png';
import tampont from '../images/tp.jpeg';
import SignatureCanvas from 'react-signature-canvas';
import { AiOutlineCheck, AiOutlineDelete } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

const RegisterForm = () => {
  const navigate = useNavigate();

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState('#186f22');
  const [isMr, setIsMr] = useState(false);
  const [isMme, setIsMme] = useState(false);
  const [isMlle, setIsMlle] = useState(false);
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [address, setAddress] = useState('');
  const [cp, setCp] = useState('');
  const [ville, setVille] = useState('');
  const [contry, setContry] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [messageSucces, setMessageSucces] = useState('');
  const [save, setSave] = useState('');

  const [isBassa, setIsBassa] = useState(false);
  const [isCheque, setIsCheque] = useState(false);
  const [isBati, setIsBati] = useState(false);
  const [isMpoo, setIsMpoo] = useState(false);
  const [isEuro, setIsEuro] = useState(false);
  const [isCfa, setIsCfa] = useState(false);
  const [isDollar, setIsDollar] = useState(false);
  const [iSparain, setIsparain] = useState(false);
  const [isSoutient, setIsSoutient] = useState(false);
  const [donateAmount, setDonateAmount] = useState('');
  const [totalAmount, setTotalAmount] = useState('');

  const [istotalAmount, setisTotalAmount] = useState(false);
  const [isByMe, setIsByMe] = useState(false);
  const [freindName, setFreindName] = useState('');
  const [isFreind, setIsFreind] = useState('');
  const [parainName, setParainName] = useState('');
  const [makeAt, setMakeAt] = useState('');
  const [date, setDate] = useState('');
  const [isRIB, setIsRIB] = useState('');
  const [signature, setSignature] = useState('');
  const [erroMessage, setErroMessage] = useState('');

  const signatureRef = React.useRef();

  const handleClearSignature = (e) => {
    e.preventDefault();
    signatureRef.current.clear();
    setSignature('');
  };

  const handleSaveSignature = async (e) => {
    e.preventDefault();
    const signatureImage = signatureRef.current.toDataURL();
    setSignature(signatureImage);
    setSave('Signature enregistrée avec succès');

    setTimeout(() => {
      setSave('');
    }, 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const modelData = {
        isMr,
        isMme,
        isMlle,
        name,
        firstName,
        address,
        cp,
        ville,
        contry,
        phone,
        email,
        isBassa,
        isBati,
        isMpoo,
        isEuro,
        isCfa,
        isDollar,
        iSparain,
        isSoutient,
        donateAmount,
        totalAmount,
        istotalAmount,
        isByMe,
        freindName,
        isFreind,
        parainName,
        makeAt,
        date,
        isRIB,
        signature,
        isCheque,
      };

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await axios.post('/api/members', modelData, config);
      toast.success('Inscription envoyé avec success !', {
        position: toast.POSITION.TOP_CENTER,
      });
      setMessageSucces('votre inscription a été effectué avec success');
      setLoading(false);

      setTimeout(() => {
        navigate('/');
      }, 3000);

      return response.data;
    } catch (error) {
      setErroMessage("une erreur s'est produite ");
      setTimeout(() => {
        setErroMessage('');
      }, 3000);
      setLoading(false);
      console.error('Erreur lors de la création du modèle:', error.message);
    }
  };

  return (
    <>
      <div className='bg-[#f1f1f1] min-h-[100vh] py-10'>
        {messageSucces && (
          <div className='w-full mx-auto text-center '>
            <span className='text-[1.2rem] text-green-700 bg-green-100 px-4 py-3 text-center'>
              {messageSucces}
            </span>
          </div>
        )}
        {erroMessage && (
          <div className='w-full text-center bg-red-100'>
            <span className='text-[1.2rem] text-red-500  px-4 py-3 text-center'></span>
            {erroMessage}
          </div>
        )}
        {loading ? (
          <div className='flex items-center justify-center w-full '>
            <ClipLoader
              color={color}
              loading={true}
              // cssOverride={override}
              size={150}
              aria-label='Loading Spinner'
              data-testid='loader'
            />
          </div>
        ) : (
          <div className='w-[98%] py-4 px-2 lg:px-20 lg:w-[60%] mx-auto min-h-[95vh]  bg-[#ffffff] shadow-md border-[1px] rounded-md'>
            <div className='flex flex-col items-center justify-center w-full '>
              <img src={logo} className='object-cover w-24 h-24 ' />
              <h3 className='font-semibold text-green-700 '>
                BULLETIN D’ADHÉSION À LIHÂÂ LI MAHOL INTERNATIONALE
              </h3>
            </div>
            <p className='pt-5 mb-2 '>
              Pour adhérer à LIHÂÂ LI MAHOL, il vous suffit de compléter et de
              nous renvoyer le bulletin ci-dessous accompagné de votre
              règlement. Un reçu vous sera envoyé dès réception
            </p>
            <form className='mb-10 ' onSubmit={handleSubmit}>
              <div className='  w-[100%]  lg:w-[50%] flex justify-between font-semibold text-[1.1rem] '>
                <div className='flex space-x-2 '>
                  <p>Je sousigné(e)s,</p>
                  <div>
                    <input
                      type='checkbox'
                      checked={isMme}
                      onChange={(e) => setIsMme(e.target.checked)}
                    />
                    <span>Mme</span>
                  </div>
                </div>
                <div>
                  <input
                    type='checkbox'
                    checked={isMr}
                    onChange={(e) => setIsMr(e.target.checked)}
                  />
                  <span>Mr</span>
                </div>
                <div>
                  <input
                    type='checkbox'
                    checked={isMlle}
                    onChange={(e) => setIsMlle(e.target.checked)}
                  />
                  <span>Mlle</span>
                </div>
              </div>
              <div className=' my-4  w-[100%]  lg:w-[50%] flex space-x-14 lg:space-x-14 items-center font-semibold text-[1.1rem]'>
                <label>Nom:</label>
                <input
                  required
                  type=' text'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className=' w-full outline-none border-[1px] border-t-[4px] border-l-[4px] p-[2px]   border-[#616161]'
                />
              </div>
              <div className=' my-4  w-[100%]  lg:w-[50%] flex space-x-8 items-center font-semibold text-[1.1rem]'>
                <label>Prénom:</label>
                <input
                  required
                  type=' text'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className=' w-full outline-none border-[1px] border-t-[4px] border-l-[4px] p-[2px]   border-[#616161]'
                />
              </div>
              <div className=' my-4 w-[100%]  lg:w-[50%] flex space-x-5 items-center font-semibold text-[1.1rem]'>
                <label>Addresse:</label>
                <input
                  type=' text'
                  required
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className=' w-full outline-none border-[1px] border-t-[4px] border-l-[4px] p-[2px]   border-[#616161]'
                />
              </div>
              <div className=' my-4 w-[100%]   flex space-x-5 items-center font-semibold text-[0.9rem] lg:text-[1.1rem]'>
                <div className='flex flex-col items-start lg:items-center lg:flex-row lg:space-x-2'>
                  <label>CP:</label>
                  <input
                    type=' text'
                    value={cp}
                    onChange={(e) => setCp(e.target.value)}
                    className=' w-full outline-none border-[1px] border-t-[4px] border-l-[4px] p-[2px]   border-[#616161]'
                  />
                </div>
                <div className='flex flex-col items-start lg:space-x-2 lg:items-center lg:flex-row'>
                  <label>Ville:</label>
                  <input
                    required
                    value={ville}
                    onChange={(e) => setVille(e.target.value)}
                    type=' text'
                    className=' w-full outline-none border-[1px] border-t-[4px] border-l-[4px] p-[2px]   border-[#616161]'
                  />
                </div>
                <div className='flex flex-col items-start lg:space-x-2 lg:items-center lg:flex-row'>
                  <label>Pays:</label>
                  <input
                    required
                    value={contry}
                    onChange={(e) => setContry(e.target.value)}
                    type=' text'
                    className=' w-full outline-none border-[1px] border-t-[4px] border-l-[4px] p-[2px]   border-[#616161]'
                  />
                </div>
              </div>
              <div className=' my-4 w-[100%]   flex space-x-5 items-center font-semibold text-[0.9rem] lg:text-[1.1rem]'>
                <div className=' lg:w-[50%] flex lg:items-center flex-col lg:flex-row  items-start lg:space-x-2'>
                  <label>Téléphone:</label>
                  <input
                    type=' text'
                    required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className=' w-[100%] outline-none border-[1px] border-t-[4px] border-l-[4px] p-[2px]   border-[#616161]'
                  />
                </div>
                <div className='lg:w-[50%] lg:space-x-2 flex lg:items-center flex-col lg:flex-row  items-start'>
                  <label>Email:</label>
                  <input
                    type='email'
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className=' w-[100%] outline-none border-[1px] border-t-[4px] border-l-[4px] p-[2px]   border-[#616161]'
                  />
                </div>
              </div>
              <div className='  w-[100%]  flex justify-between font-semibold lg:text-[1.1rem] text-[0.9rem] mb-2'>
                <div className='flex space-x-2 '>
                  <p>Je suis</p>
                  <div className='flex space-x-2 '>
                    <input
                      type='checkbox'
                      value={isBassa}
                      onChange={(e) => setIsBassa(e.target.checked)}
                    />
                    <label>BASSA</label>
                  </div>
                </div>
                <div className='flex space-x-2 '>
                  <input
                    type='checkbox'
                    value={isBati}
                    onChange={(e) => setIsBati(e.target.checked)}
                  />
                  <label>BÂTI</label>
                </div>
                <div className='flex space-x-2 '>
                  <input
                    type='checkbox'
                    checked={isMpoo}
                    onChange={(e) => setIsMpoo(e.target.checked)}
                  />
                  <label>MPO'O</label>
                </div>
              </div>
              <div className=' flex space-x-2 font-semibold text-[0.9rem] lg:text-[1.1rem] items-center '>
                <input
                  type='checkbox'
                  className='w-4 h-4'
                  checked={isEuro}
                  onChange={(e) => setIsEuro(e.target.checked)}
                />
                <label>J’adhère à LIHLIMAH pour un montant de 50 €</label>
              </div>
              <div className=' flex space-x-2 font-semibold text-[0.9rem] lg:text-[1.1rem] items-center '>
                <input
                  type='checkbox'
                  className='w-4 h-4'
                  checked={isCfa}
                  onChange={(e) => setIsCfa(e.target.checked)}
                />
                <label>
                  J’adhère à LIHLIMAH pour un montant de 30 000 FCFA
                </label>
              </div>
              <div className=' flex space-x-2 font-semibold text-[0.9rem] lg:text-[1.1rem] items-center '>
                <input
                  type='checkbox'
                  className='w-4 h-4'
                  checked={isDollar}
                  onChange={(e) => setIsDollar(e.target.checked)}
                />
                <label>J’adhère à LIHLIMAH pour un montant de 55 $</label>
              </div>
              <div className='mb-2 w-[100%] lg:w-[80%] justify-between flex-col lg:flex-row flex lg:space-x-2 font-semibold text-[1.1rem] lg:items-center '>
                <div className='flex space-x-2 font-semibold text-[0.9rem] lg:text-[1.1rem] items-center'>
                  <input
                    type='checkbox'
                    className='w-4 h-4'
                    checked={iSparain}
                    onChange={(e) => setIsparain(e.target.checked)}
                  />
                  <label>Parrainé par un tiers Nom et Prénom :</label>
                </div>
                <input
                  type=' text'
                  value={parainName}
                  onChange={(e) => setParainName(e.target.value)}
                  className='w-[100%] lg:w-[40%] outline-none border-[1px] border-t-[4px] border-l-[4px] p-[2px]   border-[#616161]'
                />
              </div>
              <div className='mb-2 w-[100%] lg:w-[80%] justify-between flex-col lg:flex-row flex lg:space-x-2 font-semibold text-[1.1rem] lg:items-center '>
                <div className='flex space-x-2 font-semibold text-[0.9rem] lg:text-[1.1rem] items-center'>
                  <input
                    type='checkbox'
                    className='w-4 h-4'
                    checked={isSoutient}
                    onChange={(e) => setIsSoutient(e.target.checked)}
                  />
                  <label>
                    je soutiens LIHLIMAH en versant un supplément de:
                  </label>
                </div>
                <input
                  type=' text'
                  className='w-[100%] lg:w-[40%] outline-none border-[1px] border-t-[4px] border-l-[4px] p-[2px]   border-[#616161]'
                  value={donateAmount}
                  onChange={(e) => setDonateAmount(e.target.value)}
                />
              </div>
              <div className='mb-2 w-[100%] lg:w-[80%] justify-between flex-col lg:flex-row flex lg:space-x-2 font-semibold text-[1.1rem] lg:items-center '>
                <div className='flex space-x-2 font-semibold text-[0.9rem] lg:text-[1.1rem] items-center'>
                  <input
                    type='checkbox'
                    className='w-4 h-4'
                    checked={istotalAmount}
                    onChange={(e) => setisTotalAmount(e.target.checked)}
                  />
                  <label>Soit un montant total de :</label>
                </div>
                <input
                  type='text'
                  className='w-[100%] lg:w-[40%] outline-none border-[1px] border-t-[4px] border-l-[4px] p-[2px]   border-[#616161]'
                  value={totalAmount}
                  onChange={(e) => setTotalAmount(e.target.value)}
                />
              </div>
              <div className='border-[1px] border-green-700'>
                <div className=' flex space-x-2 font-semibold text-[0.9rem] lg:text-[1.1rem] items-center '>
                  <input
                    type='checkbox'
                    className='w-4 h-4'
                    checked={isByMe}
                    onChange={(e) => setIsByMe(e.target.checked)}
                  />
                  <label>Par moi-même</label>
                </div>
                <div className='mb-2 w-[100%] lg:w-[80%] justify-between flex-col lg:flex-row flex lg:space-x-2 font-semibold text-[1.1rem] lg:items-center '>
                  <div className='flex space-x-2 font-semibold text-[0.9rem] lg:text-[1.1rem] items-center'>
                    <input
                      type='checkbox'
                      className='w-4 h-4'
                      checked={isFreind}
                      onChange={(e) => setIsFreind(e.target.checked)}
                    />
                    <label>Par un tiers : Nom Prénom :</label>
                  </div>
                  <input
                    type=' text'
                    className='w-[100%] lg:w-[40%] outline-none border-[1px] border-t-[4px] border-l-[4px] p-[2px]   border-[#616161]'
                    value={freindName}
                    onChange={(e) => setFreindName(e.target.value)}
                  />
                </div>
                <div className=' flex space-x-2 font-semibold text-[0.9rem] lg:text-[1.1rem] items-center '>
                  <input
                    type='checkbox'
                    className='w-4 h-4'
                    checked={isCheque}
                    onChange={(e) => setIsCheque(e.target.checked)}
                  />
                  <label>
                    Chèque à l’ordre de l’Association Lihââ Li Mahol
                    Internationale
                  </label>
                </div>
                <div className=' flex space-x-2 font-semibold text-[0.9rem] lg:text-[1.1rem] items-center '>
                  <input
                    type='checkbox'
                    className='w-4 h-4'
                    checked={isRIB}
                    onChange={(e) => setIsRIB(e.target.checked)}
                  />
                  <label>
                    Virement (RIB ci-dessous) sur le compte de Lihââ Li Mahol
                    Internationale
                  </label>
                </div>
                <div className=' border-t-[1px] border-green-700 text-center'>
                  <p className='font-bold '>IBAN</p>
                </div>
                <div className=' border-t-[1px] border-green-700 text-center'>
                  <p className='font-bold '>
                    FR76 1009 6180 8200 0433 2960 170 / BIC : CMCIFRPP
                  </p>
                </div>
                <div className=' border-t-[1px] border-green-700 text-center'>
                  <p className='font-bold '>
                    Cameroun compte MTN : +(237) 6 70 25 79 75 / Contact
                    Leocadie :+(237) 6 99 92 20 76
                  </p>
                </div>
              </div>
              <div className='flex justify-between mt-3 '>
                <div className=' lg:w-[40%] flex lg:items-center flex-col lg:flex-row  items-start lg:space-x-2'>
                  <label>Fait à :</label>
                  <input
                    type=' text'
                    value={makeAt}
                    onChange={(e) => setMakeAt(e.target.value)}
                    className=' w-[80%] outline-none border-[1px] border-t-[4px] border-l-[4px] p-[2px]   border-[#616161]'
                  />
                </div>
                <div className='lg:w-[40%] lg:space-x-2 flex lg:items-center flex-col lg:flex-row  items-start'>
                  <label> Le :</label>
                  <input
                    type=' text'
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className=' w-[80%] outline-none border-[1px] border-t-[4px] border-l-[4px] p-[2px]   border-[#616161]'
                  />
                </div>
              </div>
              <div>
                <div className='w-[100%] mt-4 lg:space-x-2 flex  justify-center flex-col items-center  '>
                  {save && (
                    <div className='w-full mb-10 text-center '>
                      <span className=' text-[1rem] text-lime-500 bg-green-100 px-4 py-3'>
                        {save}
                      </span>
                    </div>
                  )}
                  <div className='flex flex-col items-center mb-5 lg:space-x-4 lg:flex-row'>
                    <label>Cachet et/ou Signature:</label>
                    <div className='border-[1px] border-green-700 '>
                      <SignatureCanvas
                        ref={signatureRef}
                        penColor='black'
                        canvasProps={{
                          width: 200,
                          height: 100,
                          className: 'signature-canvas ',
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex space-x-10 '>
                    <button onClick={handleClearSignature}>
                      <AiOutlineDelete className='text-red-600 text-[1.2rem]' />
                    </button>
                    <button onClick={handleSaveSignature}>
                      <AiOutlineCheck className=' text-green-700 text-[1.2rem]' />
                    </button>
                  </div>
                </div>
              </div>
              <p className='mt-4 '>
                NB : En cas de non validation de votre adhésion par la
                commission de contrôle votre paiement vous sera intégralement
                remboursé
              </p>
              <div className='flex flex-col items-center w-full px-10 mt-6 lg:space-x-2 lg:flex-row lg:justify-center'>
                <img src={tampont} className='w-25 h-25 lg:h-11 lg:w-11' />
                <p className='text-center '>
                  Association Lihââ Li Mahol Internationale <br /> 43 Route de
                  Sospel 06500 MENTON / Email : lihaalimahol@gmail.com/ Tel : +
                  33 6 59 34 56 40
                </p>
              </div>
              <div className='w-full mt-5 text-center '>
                <button
                  type='submit'
                  disabled={!signature}
                  className={`px-6 py-3 text-white ${
                    signature ? 'bg-green-700' : 'bg-green-200'
                  }  w-[100%] lg:w-[20%] font-bold text-[1rem]  lg:text-[1.2rem]`}
                >
                  Envoyer
                </button>
              </div>
              <p className='w-full pt-3 text-center '>
                <span className='font-bold text-red-600'>
                  NB: valider votre signature avec l'icone vert en dessous de la
                  signature
                </span>
              </p>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default RegisterForm;
