import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Layout from '../componantes/admin/Layout';
import Spinner from '../componantes/Globals/Spinner';
import FormInput from '../componantes/Globals/FormInput';
import FormTextarea from '../componantes/Globals/FormTextarea';
import SwitchInput from '../componantes/Globals/SwitchInput';
import PrimaryButton from '../componantes/Globals/PrimaryButton';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import { useAdminRequest } from '../hooks/useAdminRequest';
import { useDocument } from '../hooks/useDocument';
import Header from '../componantes/Globals/Header';

const EdictBlog = () => {
  const { put, response } = useAdminRequest();
  const { id } = useParams();
  const { response: res } = useDocument(`/api/blogs/${id}`);
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState();
  const [imgLoading, setImgLoading] = useState();

  const [isVedette, setIsVedette] = useState(false);

  useEffect(() => {
    if (res.success) {
      setTitle(res.document.title);
      setDescription(res.document.description);
      setImage(res.document.image);
      setIsVedette(res.document.isVedette);
    }
  }, [res.success]);

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.set('image', file);

    try {
      setImgLoading(true);
      const response = await axios.post('/api/unpload/image', formData);
      console.log(response.data);
      setImage(response.data);
      setImgLoading(false);
    } catch (error) {
      setImgLoading(false);
      console.log(error);
    }
  };

  const deleteFileHadler = () => {
    setImage('');
  };

  async function submitForm(e) {
    e.preventDefault();
    const updatedFields = {
      title,
      description,
      image,
      isVedette,
    };

    put(`/api/blogs/${id}`, updatedFields);
  }

  useEffect(() => {
    if (response.error) {
      toast.error("Une erreur s'est produite", {
        position: 'top-center',
      });
    }
  }, [response.error]);

  useEffect(() => {
    if (response.success) {
      navigate('/admin/blogs');
    }
  }, [response.success]);

  return (
    <>
      <div className=' bg-[#3a3a3a] pb-2'>
        <Header />
      </div>
      <Layout>
        <div className=' bg-white shadow-sm rounded-md p-4'>
          <Spinner loading={response.isPending} />
          <h2 className=' mb-3'>Nouveau </h2>
          <form onSubmit={submitForm}>
            <FormInput
              required={true}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              title={`Titre`}
              type='text'
              placeholder={`Saisisser un titre`}
            />
            <FormTextarea
              required={true}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              title={'Description'}
              placeholder={`Donner une description du service`}
            />

            <SwitchInput setIsVedette={setIsVedette} isVedette={isVedette} />
            <label className=' w-[100px] relative' htmlFor='image'>
              {imgLoading && (
                <div className=' absolute top-1/3  left-6'>
                  <ClipLoader size={60} color='#36d7b7' />
                </div>
              )}

              <span className=' text-[1.2rem] font-medium'>Images</span>
              <input
                style={{ display: 'none' }}
                type='file'
                id='image'
                onChange={handleUpload}
              />
              <div
                title='Banniere entreprise'
                className='border-2 flex rounded-md border-dashed cursor-pointer border-[#114cb3] justify-center p-2 w-[10%]'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-10 h-10  text-[#114cb3]   '
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z'
                  />
                </svg>
              </div>
            </label>

            {image && (
              <div className=' flex space-x-2 my-4'>
                <div className=' relative z-0'>
                  <img
                    src={image}
                    alt='image'
                    className=' h-16 w-20 object-cover'
                  />
                  <svg
                    onClick={() => deleteFileHadler(image)}
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='w-6 h-6 absolute top-0 right-0 cursor-pointer text-red-500'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M6 18 18 6M6 6l12 12'
                    />
                  </svg>
                </div>
              </div>
            )}

            <div className=' mt-3'>
              <PrimaryButton text={'Enregistrer'} />
            </div>
          </form>
        </div>
      </Layout>
    </>
  );
};

export default EdictBlog;
