import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import bg from '../images/bg.jpg';
import user1 from '../images/user1.jpeg';
import user2 from '../images/user2.jpeg';
import user3 from '../images/user3.jpeg';
import user4 from '../images/user4.jpeg';
import user5 from '../images/user5.jpeg';
import logo from '../images/logo.png';
import { BiLogoFacebook } from 'react-icons/bi';
import { AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';
import Header from './Globals/Header';

const HomeHeader = () => {
  const navigate = useNavigate();

  return (
    <div className=' relative  w-[100%] h-[100vh] overflow-hidden'>
      <div className='absolute top-0 bottom-0 left-0 right-0 '>
        <img src={bg} className=' h-full w-full object-cover blur-[2]' />
      </div>
      <div className=' bg-[#000000b5] absolute top-0 left-0 right-0 bottom-0 z-10'></div>
      <Header />
      <div className=' lg:w-[70%] md:w-[85%] relative z-40 w-[98%] mx-auto h-[80%] flex flex-col justify-center '>
        <div className=' text-[1rem] lg:text-[1.2rem] text-orange-500 font-semibold mb-6'>
          <h4 className='uppercase '>
            LIHÂÂ LI MAHOL Internationale (LIHLIMAH)
          </h4>
        </div>
        <div>
          <h1 className=' text-[2rem] font-extrabold lg:text-[3.5rem] text-white'>
            Ensemble pour transformer la <br />
            communauté BASSA BATI MPOO
          </h1>
        </div>
        <div className='relative flex items-center w-[100%] lg:w-[30%] pt-5 px-4'>
          <div className='relative inline-block w-10 h-10 -ml-4 border-[4px] border-white rounded-full md:w-14 md:h-14'>
            <img
              src={user1}
              alt='Utilisateur'
              className='object-cover w-full h-full mr-2 rounded-full'
            />
          </div>
          <div className='relative inline-block w-10 h-10 -ml-4 border-[4px] border-white rounded-full md:w-14 md:h-14'>
            <img
              src={user2}
              alt='Utilisateur'
              className='w-full h-full mr-2 rounded-full '
            />
          </div>
          <div className='relative inline-block w-10 h-10 -ml-4 border-[4px] border-white rounded-full md:w-14 md:h-14'>
            <img
              src={user3}
              alt='Utilisateur'
              className='w-full h-full rounded-full'
            />
          </div>
          <div className='relative inline-block w-10 h-10 -ml-4 border-[4px] border-white rounded-full md:w-14 md:h-14'>
            <img
              src={user5}
              alt='Utilisateur'
              className='w-full h-full mr-2 rounded-full '
            />
          </div>
        </div>
        <div>
          <button
            onClick={() => navigate('/inscription')}
            className=' lg:text-[1.2rem] text-[0.9rem] rounded-md  px-6 py-3 bg-orange-600 hover:bg-orange-700 mt-5 text-white font-bold '
          >
            Réjoindre
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
