import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useHttpGet } from '../hooks/useHttpGet';
import ErreorMessage from '../componantes/Globals/ErreorMessage';
import Loader from '../componantes/Globals/Loader';
import NotFound from '../componantes/Globals/NotFound';
import Pagination from '../componantes/Pagination';
import Header from '../componantes/Globals/Header';

function Blog() {
  const { doc } = useHttpGet('/api/blogs');

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(8);
  const [activeIndex, setActiveIndex] = useState(0);

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const blogs = doc.document
    ?.filter((articles) => articles.isVedette === false)
    .slice(indexOfFirstProduct, indexOfLastProduct);

  const vedetteBlogs = doc.document.filter(
    (articles) => articles?.isVedette === true
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (vedetteBlogs?.length === 1) {
      return;
    } else {
      const intervalId = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % vedetteBlogs?.length);
      }, 3000);

      return () => clearInterval(intervalId);
    }
  }, [vedetteBlogs?.length]);

  return (
    <>
      <div className=' bg-[#3a3a3a] pb-2'>
        <Header />
      </div>
      {doc?.isPending ? (
        <Loader />
      ) : doc?.error ? (
        <ErreorMessage message={'Erreur de recuperation des blogs'} />
      ) : doc?.document?.length === 0 ? (
        <NotFound message='Aucun Blog trouvée' />
      ) : (
        <main>
          {vedetteBlogs.length > 0 && (
            <div className=' h-[60vh] max-h-[60vh] min-h-[45vh] relative w-full'>
              {console.log(vedetteBlogs[activeIndex]?.image[0].substring(11))}
              <Link to={`/blog-detail/${vedetteBlogs[activeIndex]?._id}`}>
                <img
                  src={`${vedetteBlogs[activeIndex]?.image} `}
                  alt=''
                  className=' h-full w-full object-cover'
                />
              </Link>
              <div className='flex absolute left-1/2 -translate-x-1/2 -translate-y-1/2 bottom-0 justify-center mt-4'>
                {Array.from({ length: vedetteBlogs?.length }).map(
                  (_, index) => (
                    <div
                      onClick={() => setActiveIndex(index)}
                      key={index}
                      className={`h-5 w-5   rounded-full  mx-2 cursor-pointer ${
                        activeIndex === index ? 'bg-white' : ' bg-gray-400'
                      }`}
                    ></div>
                  )
                )}
              </div>
            </div>
          )}

          {blogs.length > 0 && (
            <div className=' w-[95%]  xl:w-[65%] mx-auto mt-5'>
              <Link
                to={`/blog-detail/${blogs[0]?._id}`}
                className='grid gap-3 grid-cols-3 grid-rows-auto'
              >
                <div className=' col-span-3 md:col-span-2'>
                  <div className=' h-[350px] md:h-[400px]'>
                    <img
                      src={blogs[0]?.image}
                      alt=''
                      className=' w-full h-full object-cover'
                    />
                  </div>
                </div>
                <div className=' col-span-3 md:col-span-1'>
                  <h1 className=' text-[1.4rem] font-semibold'>
                    {blogs[0]?.title}
                  </h1>
                  <p>
                    {blogs[0]?.description?.length > 300
                      ? `${blogs[0]?.description.substring(0, 300)}` + '...'
                      : blogs[0]?.description}
                  </p>
                </div>
              </Link>
              <div className='grid annonce  mt-5 '>
                {blogs.slice(1, doc?.document?.length).map((blog, index) => {
                  return (
                    <Link to={`/blog-detail/${blog[0]?._id}`} key={index}>
                      <div>
                        <img
                          src={blog?.image}
                          alt=''
                          className=' w-full object-cover h-48'
                        />
                      </div>
                      <h1 className=' text-[1.2rem] font-semibold'>
                        {blog?.title}
                      </h1>
                      <p>
                        {blogs[0]?.description?.length > 200
                          ? `${blogs[0]?.description.substring(0, 200)}` + '...'
                          : blogs[0]?.description}
                      </p>
                    </Link>
                  );
                })}
              </div>
            </div>
          )}

          {blogs?.length > 0 && (
            <Pagination
              documents={blogs}
              productsPerPage={productsPerPage}
              currentPage={currentPage}
              paginate={paginate}
            />
          )}
        </main>
      )}
    </>
  );
}

export default Blog;
