import React from 'react';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#1e4ea0',
  // width: '100%',
};

const Loader = () => {
  return (
    <div className=' h-full w-full flex justify-center items-center'>
      <ClimbingBoxLoader
        color='#1e4ea0'
        loading={true}
        cssOverride={override}
        size={20}
        data-testid='loader'
      />
    </div>
  );
};

export default Loader;
