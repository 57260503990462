import React from 'react';
import BarLoader from 'react-spinners/BarLoader';

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#114cb3',
  width: '100%',
};

const Spinner = ({ loading }) => {
  return (
    <BarLoader
      color='#114cb3'
      loading={loading}
      cssOverride={override}
      size={150}
      data-testid='loader'
    />
  );
};

export default Spinner;
