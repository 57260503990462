import React, { useState } from 'react';
import logo from '../images/logo.png';
import tampont from '../images/tp.jpeg';
import SignatureCanvas from 'react-signature-canvas';
import { AiOutlineCheck, AiOutlineDelete } from 'react-icons/ai';
import axios from 'axios';

const TespForm = () => {
  const [isMr, setIsMr] = useState(false);
  const [isMme, setIsMme] = useState(false);
  const [isMlle, setIsMlle] = useState(false);
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [address, setAddress] = useState('');
  const [cp, setCp] = useState('');
  const [ville, setVille] = useState('');
  const [contry, setContry] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [isBassa, setIsBassa] = useState(false);
  const [isBati, setIsBati] = useState(false);
  const [isMpoo, setIsMpoo] = useState(false);
  const [isEuro, setIsEuro] = useState(false);
  const [isCfa, setIsCfa] = useState(false);
  const [isDollar, setIsDollar] = useState(false);
  const [iSparain, setIsparain] = useState(false);
  const [isSoutient, setIsSoutient] = useState(false);
  const [donateAmount, setDonateAmount] = useState('');
  const [totalAmount, setTotalAmount] = useState('');

  const [istotalAmount, setisTotalAmount] = useState(false);
  const [isByMe, setIsByMe] = useState(false);
  const [freindName, setFreindName] = useState('');
  const [isFreind, setIsFreind] = useState('');
  const [parainName, setParainName] = useState('');
  const [makeAt, setMakeAt] = useState('');
  const [date, setDate] = useState('');
  const [isRIB, setIsRIB] = useState('');
  const [signature, setSignature] = useState('');

  const signatureRef = React.useRef();

  const handleClearSignature = (e) => {
    e.preventDefault();
    signatureRef.current.clear();
  };

  const handleSaveSignature = async (e) => {
    e.preventDefault();
    const signatureImage = signatureRef.current.toDataURL();
    setSignature(signatureImage);
    // const formData = new FormData();
    // formData.append('signatureImage', signatureImage);

    // try {
    //   const { data } = await axios.post('/api/upload', formData);
    //   console.log(data);
    //   setSignature(data);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const modelData = {
        isMr,
        isMme,
        isMlle,
        name,
        firstName,
        address,
        cp,
        ville,
        contry,
        phone,
        email,
        isBassa,
        isBati,
        isMpoo,
        isEuro,
        isCfa,
        isDollar,
        iSparain,
        isSoutient,
        donateAmount,
        totalAmount,
        istotalAmount,
        isByMe,
        freindName,
        isFreind,
        parainName,
        makeAt,
        date,
        isRIB,
        signature,
      };
      console.log(modelData);

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await axios.post('/api/members', modelData, config);
      return response.data;
    } catch (error) {
      console.error('Erreur lors de la création du modèle:', error.message);
    }
  };
  const [isCheque, setIsCheque] = useState(false);
  return (
    <div className='bg-[#f1f1f1] min-h-[100vh] py-10'>
      <div className='w-[98%] py-4 px-2 lg:px-20 lg:w-[60%] mx-auto min-h-[95vh]  bg-[#ffffff] shadow-md border-[1px] rounded-md'>
        <div className='flex flex-col items-center justify-center w-full '>
          <img src={logo} className='object-cover w-24 h-24 ' />
          <h3 className='font-semibold text-green-700 '>
            BULLETIN D’ADHÉSION À LIHÂÂ LI MAHOL INTERNATIONALE
          </h3>
        </div>
        <p className='pt-5 mb-2 '>
          Pour adhérer à LIHÂÂ LI MAHOL, il vous suffit de compléter et de nous
          renvoyer le bulletin ci-dessous accompagné de votre règlement. Un reçu
          vous sera envoyé dès réception
        </p>
        <div className='mb-10 '>
          <div className=' w-[50%] flex justify-between font-semibold text-[1.1rem] '>
            <div className='flex space-x-2 '>
              <p>Je sousigné(e)s,</p>
              <div>
                <input
                  type='checkbox'
                  value={isMme}
                  onChange={(e) => setIsMme(e.target.checked)}
                />
                <span>Mme</span>
              </div>
            </div>
            <div>
              <input
                type='checkbox'
                value={isMr}
                onChange={(e) => setIsMr(e.target.checked)}
              />
              <span>Mr</span>
            </div>
            <div>
              <input
                type='checkbox'
                value={isMlle}
                onChange={(e) => setIsMlle(e.target.checked)}
              />
              <span>Mlle</span>
            </div>
          </div>
          <div className=' my-4  w-[50%] flex space-x-14 items-center font-semibold text-[1.1rem]'>
            <span>Nom:</span>
            <span className=' w-[100%]'> {name}</span>
          </div>
          <div className=' my-4 w-[50%] flex space-x-8 items-center font-semibold text-[1.1rem]'>
            <span>Prénom:</span>
            <span className=' w-[100%]'> {firstName}</span>
          </div>
          <div className=' my-4 w-[50%] flex space-x-5 items-center font-semibold text-[1.1rem]'>
            <span>Addresse:</span>
            <span className=' w-[100%]'> {address}</span>
          </div>
          <div className=' my-4 w-[100%]   flex space-x-5 items-center font-semibold text-[1.1rem]'>
            <div className='flex items-center space-x-2'>
              <span>CP:</span>
              <span className=' w-[100%]'> {cp}</span>
            </div>
            <div className='flex items-center space-x-2 '>
              <span>Ville:</span>
              <span className=' w-[100%]'> {ville}</span>
            </div>
            <div className='flex items-center space-x-2'>
              <span>Pays:</span>
              <span className=' w-[100%]'> {contry}</span>
            </div>
          </div>
          <div className=' my-4   flex space-x-5 items-center font-semibold  text-[1.1rem]'>
            <div className=' w-[50%] flex items-center   space-x-2'>
              <span>Téléphone:</span>
              <span className=' w-[100%]'> {phone}</span>
            </div>
            <div className='w-[50%] space-x-2 flex items-center  '>
              <span>Email:</span>
              <span className=' w-[100%]'> {email}</span>
            </div>
          </div>
          <div className='  w-[100%]  flex justify-between font-semibold text-[1.1rem] mb-2'>
            <div className='flex space-x-2 '>
              <p>Je suis</p>
              <div className='flex space-x-2 '>
                <input
                  type='checkbox'
                  value={isBassa}
                  onChange={(e) => setIsBassa(e.target.checked)}
                />
                <span>BASSA</span>
              </div>
            </div>
            <div className='flex space-x-2 '>
              <input
                type='checkbox'
                value={isBati}
                onChange={(e) => setIsBati(e.target.checked)}
              />
              <span>BÂTI</span>
            </div>
            <div className='flex space-x-2 '>
              <input
                type='checkbox'
                value={isMpoo}
                onChange={(e) => setIsMpoo(e.target.value)}
              />
              <span>MPO'O</span>
            </div>
          </div>
          <div className=' flex space-x-2 font-semibold  text-[1.1rem] items-center '>
            <input
              type='checkbox'
              className='w-4 h-4'
              value={isEuro}
              onChange={(e) => setIsEuro(e.target.value)}
            />
            <span>J’adhère à LIHLIMAH pour un montant de 50 €</span>
          </div>
          <div className=' flex space-x-2 font-semibold text-[1.1rem] items-center '>
            <input
              type='checkbox'
              className='w-4 h-4'
              value={isCfa}
              onChange={(e) => setIsCfa(e.target.value)}
            />
            <span>J’adhère à LIHLIMAH pour un montant de 30 000 FCFA</span>
          </div>
          <div className=' flex space-x-2 font-semibold text-[1.1rem] items-center '>
            <input
              type='checkbox'
              className='w-4 h-4'
              value={isDollar}
              onChange={(e) => setIsDollar(e.target.value)}
            />
            <span>J’adhère à LIHLIMAH pour un montant de 55 $</span>
          </div>
          <div className='mb-2 w-[80%] justify-between  flex space-x-2 font-semibold text-[1.1rem] items-center '>
            <div className='flex space-x-2 font-semibold text-[1.1rem] items-center'>
              <input
                type='checkbox'
                className='w-4 h-4'
                value={iSparain}
                onChange={(e) => setIsparain(e.target.value)}
              />
              <span>Parrainé par un tiers Nom et Prénom :</span>
            </div>
            <span className=' w-[40%]'> {parainName}</span>
          </div>
          <div className='mb-2 w-[80%] justify-between flex space-x-2 font-semibold text-[1.1rem] items-center '>
            <div className='flex space-x-2 font-semibold text-[1.1rem] items-center'>
              <input
                type='checkbox'
                className='w-4 h-4'
                value={isSoutient}
                onChange={(e) => setIsSoutient(e.target.value)}
              />
              <span>je soutiens LIHLIMAH en versant un supplément de:</span>
            </div>
            <span className=' w-[40%]'> {donateAmount}</span>
          </div>
          <div className='mb-2 w-[80%] justify-between  flex space-x-2 font-semibold text-[1.1rem] items-center '>
            <div className='flex space-x-2 font-semibold text-[1.1rem] items-center'>
              <input
                type='checkbox'
                className='w-4 h-4'
                value={istotalAmount}
                onChange={(e) => setisTotalAmount(e.target.value)}
              />
              <span>Soit un montant total de :</span>
            </div>
            <span className=' w-[40%]'> {totalAmount}</span>
          </div>
          <div className='border-[1px] border-green-700'>
            <div className=' flex space-x-2 font-semibold text-[1.1rem] items-center '>
              <input
                type='checkbox'
                className='w-4 h-4'
                value={isByMe}
                onChange={(e) => setIsByMe(e.target.value)}
              />
              <span>Par moi-même</span>
            </div>
            <div className='mb-2 w-[80%] justify-between  flex space-x-2 font-semibold text-[1.1rem] items-center '>
              <div className='flex space-x-2 font-semibold text-[1.1rem] items-center'>
                <input
                  type='checkbox'
                  className='w-4 h-4'
                  value={isFreind}
                  onChange={(e) => setIsFreind(e.target.value)}
                />
                <span>Par un tiers : Nom Prénom :</span>
              </div>
              <span className=' w-[40%]'> {freindName}</span>
            </div>
            <div className=' flex space-x-2 font-semibold text-[1.1rem] items-center '>
              <input type='checkbox' className='w-4 h-4' />
              <span>
                Chèque à l’ordre de l’Association Lihââ Li Mahol Internationale
              </span>
            </div>
            <div className=' flex space-x-2 font-semibold text-[1.1rem] items-center '>
              <input
                type='checkbox'
                className='w-4 h-4'
                value={isRIB}
                onChange={(e) => setIsRIB(e.target.value)}
              />
              <span>
                Virement (RIB ci-dessous) sur le compte de Lihââ Li Mahol
                Internationale
              </span>
            </div>
            <div className=' border-t-[1px] border-green-700 text-center'>
              <p className='font-bold '>IBAN</p>
            </div>
            <div className=' border-t-[1px] border-green-700 text-center'>
              <p className='font-bold '>
                FR76 1009 6180 8200 0433 2960 170 / BIC : CMCIFRPP
              </p>
            </div>
            <div className=' border-t-[1px] border-green-700 text-center'>
              <p className='font-bold '>
                Cameroun compte MTN : +(237) 6 70 25 79 75 / Contact Leocadie
                :+(237) 6 99 92 20 76
              </p>
            </div>
          </div>
          <div className='flex justify-between mt-3 '>
            <div className=' w-[40%] flex items-center   space-x-2'>
              <span>Fait à :</span>
              <span className=' w-[80%]'> {makeAt}</span>
            </div>
            <div className='w-[40%] space-x-2 flex items-center   '>
              <span> Le :</span>
              <span className=' w-[80%]'> {date}</span>
            </div>
          </div>
          <div>
            <div className='w-[100%] mt-4 space-x-2 flex  justify-center flex-col items-center  '>
              <div className='flex flex-row items-center mb-5 space-x-4'>
                <span>Cachet et/ou Signature:</span>
                <div className='border-[1px] border-green-700'>
                  {/* signature */}
                </div>
              </div>
            </div>
          </div>
          <p className='mt-4 '>
            NB : En cas de non validation de votre adhésion par la commission de
            contrôle votre paiement vous sera intégralement remboursé
          </p>
          <div className='flex flex-col items-center px-10 mt-6 lg:space-x-2 lg:flex-row'>
            <img src={tampont} className='w-20 h-20 lg:h-11 lg:w-11' />
            <p className='text-center '>
              Association Lihââ Li Mahol Internationale <br /> 43 Route de
              Sospel 06500 MENTON / Email : lihaalimahol@gmail.com/ Tel : + 33 6
              59 34 56 4
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TespForm;
