import axios from 'axios';
import { useEffect, useReducer } from 'react';

const initialState = {
  document: null,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'IS_PENDING':
      return { ...state, isPending: true };
    case 'IS_SUCCESS':
      return {
        document: action.payload,
        isPending: false,
        error: null,
        success: true,
      };

    case 'IS_ERROR':
      return {
        document: null,
        isPending: false,
        error: action.payload,
        success: false,
      };

    default:
      return state;
  }
};

export const useDocument = (url) => {
  const [response, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const getDoc = async () => {
      dispatch({ type: 'IS_PENDING' });
      try {
        const { data } = await axios.get(url);
        dispatch({ type: 'IS_SUCCESS', payload: data });
      } catch (error) {
        console.log(error);
        dispatch({ type: 'IS_ERROR', payload: error.message });
      }
    };

    getDoc();
    return () => {};
  }, []);

  return { response };
};
