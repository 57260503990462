import React from 'react';

const AuthCrad = ({ children, title }) => {
  return (
    <main className=' flex justify-center h-[100vh] items-center'>
      <div className='  w-[95%] xl:w-[70%] 2xl:w-[30%] p-2 md:p-8  bg-[#dfdfdf] rounded-md shadow-md  '>
        <h2 className=' text-[1.2rem] font-semibold'>{title}</h2>
        {children}
      </div>
    </main>
  );
};

export default AuthCrad;
