import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../componantes/Globals/Header';
import { useDocument } from '../hooks/useDocument';
import Loader from '../componantes/Globals/Loader';
import ErreorMessage from '../componantes/Globals/ErreorMessage';
import NotFound from '../componantes/Globals/NotFound';

export const BlogDetails = () => {
  const { id } = useParams();
  const { response } = useDocument(`/api/blogs/${id}`);

  return (
    <div>
      <div className=' bg-[#3a3a3a] pb-2'>
        <Header />
      </div>
      {response.isPending ? (
        <div className=' flex w-full items-center justify-center'>
          <Loader />
        </div>
      ) : response.error ? (
        <ErreorMessage message={'Erreur de recuperation du blogs'} />
      ) : !response?.document ? (
        <NotFound message='Aucun blog trouvé' />
      ) : (
        <div className=' w-[97%] lg:w-[60%] mb-6 mx-auto'>
          <div>
            <img
              src={response?.document?.image}
              alt=''
              className=' w-full mx-auto h-[450px] rounded-md object-cover mt-1'
            />
          </div>
          <h1 className=' mt-1 font-bold text-[1.6rem]'>
            {response?.document?.title}
          </h1>
          <p>{response?.document?.description}</p>
        </div>
      )}
    </div>
  );
};
