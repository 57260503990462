import React from 'react';

const FormInput = ({
  title,
  type,
  placeholder,
  value,
  onChange,
  disable,
  required,
}) => {
  return (
    <div className=' flex flex-col '>
      <label className=' text-base md:text-[1.2rem] font-medium' htmlFor={type}>
        {title}
      </label>
      <input
        required={required}
        disabled={disable}
        value={value}
        onChange={onChange}
        id={type}
        className=' bg-[#e8e8e8] p-[0.45rem] text-sm md:text-[1rem] rounded-sm outline-none '
        type={type}
        placeholder={placeholder}
      />
    </div>
  );
};

export default FormInput;
