import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Header from './componantes/Globals/Header';
import RegisterForm from './pages/RegisterForm';
import SignatureListPdf from './pages/SignatureListPdf';
import TespForm from './pages/TespForm';
import { QuiSommesNous } from './pages/QuiSommesNous';
import { Auth } from './pages/Auth';
import Blog from './pages/blog';
import AdminBlogs from './admin/AdminBlogs';
import AddBlog from './admin/AddBlog';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { BlogDetails } from './pages/BlogDetails';
import EdictBlog from './admin/EdictBlog';

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#1e4ea0',
  // width: '100%',
};

function App() {
  const [user, setUser] = useState('');
  const [userLoading, setUserLoading] = useState(false);
  useEffect(() => {
    const getuser = () => {
      const user = JSON.parse(localStorage.getItem('user'));
      setUser(user);
      setUserLoading(true);
    };
    getuser();
    return () => {};
  }, []);

  return (
    <>
      {' '}
      <ToastContainer />
      {userLoading ? (
        <BrowserRouter>
          <Routes>
            <Route element={<Home />} path={'/'} />
            <Route element={<RegisterForm />} path={'/inscription'} />
            <Route element={<SignatureListPdf />} path={'/pdf-telecharment'} />
            <Route element={<TespForm />} path={'/test-telecharment'} />
            <Route element={<QuiSommesNous />} path={'/qui-sommes-nous'} />
            <Route element={<Auth />} path={'/connexion'} />
            <Route element={<Blog />} path={'/blogs'} />
            <Route element={<BlogDetails />} path={'/blog-detail/:id'} />
            <Route
              element={
                user && user.token && user.isAdmin ? (
                  <AdminBlogs />
                ) : (
                  <Navigate to='/' replace />
                )
              }
              path={'/admin/blogs'}
            />
            <Route
              element={
                user && user.token && user.isAdmin ? (
                  <EdictBlog />
                ) : (
                  <Navigate to='/' replace />
                )
              }
              path={'/edict/blogs/:id'}
            />
            <Route
              element={
                user && user.token && user.isAdmin ? (
                  <AddBlog />
                ) : (
                  <Navigate to='/' replace />
                )
              }
              path={'/blog/add'}
            />
          </Routes>{' '}
        </BrowserRouter>
      ) : (
        ''
      )}
    </>
  );
}

export default App;
